import _ from 'lodash';
import { useI18n } from 'vue-i18n';

/**
 *
 * @param list
 * @param sliceIndex
 * @param start
 * @param stringWithXMore
 */
function SliceList(
	list,
	sliceIndex,
	start = true,
	stringWithXMore = { key: '', separator: '', i18n: '' },
) {
	if (!list || _.isEmpty(list)) return [];
	const slice = start ? list.slice(0, sliceIndex) : list.slice(sliceIndex);
	if (stringWithXMore.separator) {
		const { t } = useI18n();
		if (list.length == slice.length)
			if (slice.length == 1)
				return stringWithXMore.key ? slice[0][stringWithXMore.key] : slice[0];
			else
				return stringWithXMore.key
					? _.map(slice, (s) => s[stringWithXMore.key]).join(stringWithXMore.separator)
					: slice.join(stringWithXMore.separator);
		else
			return stringWithXMore.key
				? _.map(slice, (s) => s[stringWithXMore.key]).join(stringWithXMore.separator) +
						t(stringWithXMore.i18n, list.length - slice.length)
				: slice.join(stringWithXMore.separator) +
						t(stringWithXMore.i18n, list.length - slice.length);
	}
	return slice;
}

export { SliceList };
